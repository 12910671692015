<template>
  <div class="main-content lucky-four-my-bets pa-4 pa-sm-6 pa-md-8">
    <v-card class="bs-shadow-none">
      <div class="d-flex bs-justify-content-between bs-gap-3 pa-4 pa-sm-6">
        <div class="d-flex flex-column">
          <h3 class="font-weight-bold">Transactions</h3>
          <div class="grey--text mt-2">Transaction history list.</div>
        </div>

        <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" width="300">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              icon
              class="light"
              :color="modal ? 'primary' : undefined"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small> mdi-calendar-clock </v-icon>
            </v-btn>
          </template>
          <v-date-picker v-model="date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text @click="modal = false"> Cancel </v-btn>
            <v-btn depressed color="primary" @click="filterDate"> Ok </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>

      <v-divider></v-divider>

      <v-data-table
        :hide-default-footer="true"
        loading-text="Loading, please wait"
        :headers="headers"
        :items="records"
        :items-per-page="item_per_page_value"
        :loading="recordsLoading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :page.sync="page"
        @page-count="page_count = $event"
      >
        <template v-slot:[`item.bet_name`]="{ item }">
          <span v-if="item.bet_name">
            {{ item.bet_name }}
          </span>
          <span v-else>-</span>
        </template>

        <template v-slot:[`item.bet_amount`]="{ item }">
          <span>{{ moneyFormat(parseFloat(item.bet_amount)) }}</span>
        </template>

        <template v-slot:[`item.bet_at`]="{ item }">
          <span>{{ formatTheDate(item.bet_at) }}</span>
        </template>

        <template v-slot:[`item.results`]="{ item }">
          <v-chip
            v-if="item.game_status_id"
            small
            outlined
            :color="
              betStatus(item.results) == 'Won'
                ? 'green white--text'
                : betStatus(item.results) == 'Lose'
                ? 'red white--text'
                : 'primary white--text'
            "
          >
            {{ betStatus(item.results) }}
          </v-chip>
          <span v-else>-</span>
        </template>

        <template v-slot:[`item.game`]="{ item }">
          <div class="d-flex align-center bs-gap-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  :src="item.team_a_logo"
                  max-width="24"
                  max-height="24"
                  v-bind="attrs"
                  v-on="on"
                ></v-img>
              </template>
              <span>{{ item.team_a_alias }}</span>
            </v-tooltip>

            <span>VS</span>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  :src="item.team_b_logo"
                  max-width="24"
                  max-height="24"
                  v-bind="attrs"
                  v-on="on"
                ></v-img>
              </template>
              <span>{{ item.team_b_alias }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.game_status`]="{ item }">
          <v-chip
            v-if="item.game_status"
            small
            :class="item.game_status ? 'green white--text' : 'orange white--text'"
          >
            {{ item.game_status }}
          </v-chip>
          <v-chip v-else small class="light"> Not yet started </v-chip>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click="viewDetails(item)"
                class="d-none d-sm-inline light"
              >
                <v-icon> mdi-card-search-outline </v-icon>
              </v-btn>
            </template>
            <span>View Details</span>
          </v-tooltip>

          <a href="#" @click.prevent="viewDetails(item)" class="d-inline d-sm-none">
            View Details
          </a>
        </template>
      </v-data-table>

      <div v-if="records && records.length != 0">
        <v-divider></v-divider>

        <div class="d-flex align-center justify-space-between bs-gap-3 pa-4 pa-sm-6">
          <div class="d-flex align-center bs-gap-3">
            <v-select
              :value="item_per_page_value"
              :items="item_per_page"
              outlined
              dense
              label="Items"
              :hide-details="true"
              style="width: 100px"
              @change="item_per_page_value = parseInt($event, 10)"
            ></v-select>
            <div>
              Page
              {{
                page ? page : "0" + "-" + item_per_page_value ? item_per_page_value : "0"
              }}
              of {{ page_count ? page_count : "0" }}
            </div>
          </div>
          <v-pagination
            v-model="page"
            :length="page_count"
            :total-visible="0"
          ></v-pagination>
        </div>
      </div>
    </v-card>

    <v-dialog v-model="dialog" scrollable max-width="500">
      <v-card :outlined="$vuetify.theme.dark ? true : false">
        <v-btn icon @click="dialog = false" absolute right style="top: 10px; right: 5px">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="py-3 px-4"> Transaction Details </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-4 px-3">
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>Transaction Date</th>
                  <td>{{ formatTheDate(viewData.bet_at) }}</td>
                </tr>
                <tr>
                  <th>Combination #</th>
                  <td>{{ viewData.combination_number }}</td>
                </tr>
                <tr v-if="appData && appData.account_details.agent_id">
                  <th>Name</th>
                  <td v-if="viewData.bet_name">{{ viewData.bet_name }}</td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <th>Token</th>
                  <td>{{ moneyFormat(parseFloat(viewData.bet_amount)) }}</td>
                </tr>
                <tr>
                  <th>Game</th>
                  <td>
                    <div>
                      <span class="text-caption"
                        >{{ viewData.team_a_name }} {{ viewData.team_a_alias }}</span
                      >
                      <span class="text-overline my-2 mx-2 font-italic">vs</span>
                      <span class="text-caption"
                        >{{ viewData.team_b_name }} {{ viewData.team_b_alias }}</span
                      >
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Game Time</th>
                  <td>{{ formatTheDate(viewData.game_time) }}</td>
                </tr>
                <tr>
                  <th>Game Status</th>
                  <td>
                    <v-chip
                      v-if="viewData.game_status"
                      small
                      :class="
                        viewData.game_status ? 'green white--text' : 'orange white--text'
                      "
                    >
                      {{ viewData.game_status }}
                    </v-chip>
                    <v-chip v-else small class="light"> Not yet started </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <h5 class="subtitle-1 success--text mt-3 font-weight-bold">Game Results</h5>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Winning Combination</th>
                  <th>Status</th>
                  <th>Prize Token</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in viewData.results" :key="item.quarter">
                  <td>{{ item.winning_combination || "-" }}</td>
                  <td>
                    <v-chip
                      v-if="viewData.game_status_id"
                      small
                      outlined
                      :color="
                        item.status == 'Won'
                          ? 'green'
                          : item.status == 'Lose'
                          ? 'red white--text'
                          : 'primary white--text'
                      "
                    >
                      {{ item.status }}
                    </v-chip>
                    <span v-else>-</span>
                  </td>
                  <td>
                    {{ item.prize > 0 ? moneyFormat(parseFloat(item.prize)) : "-" }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import formatDate from "@/helpers/formatDate";
import { mapActions, mapState } from "vuex";

export default {
  data: () => ({
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date: "",
    // menu: false,
    modal: false,
    sortBy: "bet_at",
    sortDesc: true,
    headers: [
      { text: "Combination", value: "combination_number", class: "text-uppercase" },
      { text: "Token", value: "bet_amount", class: "text-uppercase" },
      { text: "Transaction Date", value: "bet_at", class: "text-uppercase" },
      {
        text: "Transaction Status",
        value: "results",
        sortable: false,
        class: "text-uppercase",
      },
      { text: "Game", sortable: false, value: "game", class: "text-uppercase" },
      {
        text: "Game Status",
        sortable: false,
        value: "game_status",
        class: "text-uppercase",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        class: "text-uppercase",
        align: "right",
      },
    ],
    dialog: false,
    viewData: null,

    page: 1,
    page_count: 0,
    item_per_page: [5, 10, 20, 40, 80],
    item_per_page_value: 10,
  }),

  created() {
    this.$store.commit("lucky_four_my_bets/setRecords", []);
    this.changeHeaders();
    this.loadRecords();
  },

  computed: {
    ...mapState("lucky_four_my_bets", ["records", "recordsLoading"]),
    ...mapState(["appData", "accountStatus"]),

    dateFormatted() {
      if (!this.date) {
        return "Filter";
      } else {
        if (this.date == this.today) {
          return "Today";
        } else {
          return formatDate(this.date, "date");
        }
      }
    },
  },

  methods: {
    ...mapActions("lucky_four_my_bets", ["getRecords"]),

    formatTheDate(theDate) {
      return formatDate(theDate);
    },

    filterDate() {
      this.$refs.dialog.save(this.date);

      this.loadRecords();
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    changeHeaders() {
      if (
        (this.appData && this.appData.account_details.agent_id) ||
        (this.appData &&
          this.appData.account_details.investor_sub_agent_player_id &&
          this.appData.account_details.investor_sub_type_id != 2 &&
          this.appData.account_details.investor_type_id != 1)
      ) {
        this.headers.splice(1, 0, {
          text: "Name",
          value: "bet_name",
          class: "text-uppercase",
        });
      }
    },

    async loadRecords() {
      if (!this.recordsLoading) {
        const formData = new FormData();
        formData.append("date_start", this.date);

        const getData = new URLSearchParams(formData);

        await this.getRecords(getData);
      }
    },

    betStatus(results) {
      if (results.slice(-1)[0].status == "Ongoing") {
        return "Ongoing";
      } else {
        const hasWon = results.filter((result) => result.status == "Won");

        if (hasWon.length) {
          return "Won";
        } else {
          return "Lose";
        }
      }
    },

    viewDetails(item) {
      this.viewData = item;
      this.dialog = true;
    },
  },

  watch: {
    accountStatus(val) {
      if (val) {
        this.changeHeaders();
        this.loadRecords();
      }
    },
  },
};
</script>
